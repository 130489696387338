// fonts

@font-face {
  font-family: 'PTsans';
  src: local('PTsans'), url(../../fonts/PTSans-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'PTsans';
  src: local('PTsans'), url(../../fonts/PTSans-Bold.ttf) format('truetype');
  font-weight: 700;
}

body {
  font-family: 'PTsans' !important;
  font-weight: 400 !important;

  pre {
    font-family: 'PTsans' !important;
  }
}

// font sizes
// link-font-size
$nav-link-font-size: 12px;

// h1-font-size
$h1-font-size: 48px;
// h2-font-size
$h2-font-size: 36px;
// h3-font-size
$h3-font-size: 28px;
// h4-font-size
$h4-font-size: 22px;
// h5-font-size
$h5-font-size: 16px;
// h6-font-size
$h6-font-size: 14px;

$sub-heading: 26px;

$maintitle: 56px;
$sub-font: 20px;

// para-font-size
$para-font-sizes: 18px;
$para-sub-font-size: 28px;
$para-sub-tab-size: 27px;
$para-title-font-size: 26px;
$para-mid-font-size: 32px;
$para-large-font-size: 40px;

// font-weight styles
// bold fonts
$bold-font: 800;

// normal fonts
$normal-font: 700;

// light fonts
$light-font: 400;

// regular fonts weight
$regular-font: 500;
