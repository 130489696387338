// Importing styles
@use '../custom' as *;
@use '../components/mixins' as *;
@use '../components/typography' as *;

/* ---------- Header CSS------------- */
#top_sub_header {
  background-color: $primary;
  padding-top: 8px;
  padding-bottom: 8px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  background-color: #e6f1ff;
  .sub_header {
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    .header_numbers {
      @include flex-row;
      justify-content: space-between;
      @include breakpoint(ipad) {
        justify-content: center;
      }
      .aot_main_title {
        margin-bottom: 0px;
        font-size: $sub-font;
        color: $buttonbg;
        font-weight: $normal-font;
        margin-right: 15px;
        @include breakpoint(mobile) {
          font-size: $para-font-sizes;
        }
      }
      .custom-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-color: $secondary;
        border-radius: 50%;
        margin-right: 8px;
        text-align: center;
        line-height: 1.4;
        .fa-phone {
          color: $white;
          font-size: $h6-font-size;
          @include breakpoint(mobile) {
            font-size: 12px;
            vertical-align: baseline;
          }
        }
      }
      ul {
        @include flex-row;
        margin: 0;
        padding: 0;
        li {
          padding-left: 15px;
          padding-right: 15px;
          list-style: none;
          &:last-child {
            padding-right: 0px;
          }
          &:first-child {
            padding-left: 0px;
          }
        }
      }
    }
  }
}

#main_header {
  border-bottom: 0.6px solid #d2d0d0;
  padding-top: 2px;
  padding-bottom: 2px;
  // position: fixed;
  width: 100%;
  // top: 46px;
  // z-index: 4;
  background-color: #fff;
  margin-top: 46.8px;

  #navbarSupportedContent {
    justify-content: center;
    .nav-item {
      padding-left: 25px;
      padding-right: 25px;
      @include breakpoint(laptoplarge) {
        padding-left: 15px;
        padding-right: 15px;
      }

      &.active {
        .nav-link {
          color: $buttonbg;
          font-weight: $normal-font;
        }
      }
      .nav-link {
        color: $text;
        font-size: $sub-font;
        &:hover {
          color: $buttonbg;
        }
      }
    }
  }
  .common_button {
    font-size: $h4-font-size;
    color: $white;
    background-color: $buttonbg;
    padding: 12px 30px;
    border-radius: 10px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    transition: all ease-in 0.2s;
    border: 1px solid transparent;
    font-weight: $normal-font;
    &:hover {
      background-color: $white !important;
      color: $buttonbg !important;
      border-color: $buttonbg !important;
    }
    @include breakpoint(largescreens) {
      padding: 7px 26px;
    }
    @include breakpoint(ipad) {
      margin-right: 30px;
      padding: 6px 23px;
      font-size: $sub-font;
    }
    @include breakpoint(fix-size) {
      margin-right: 40px;
    }
    @include breakpoint(mobile) {
      margin-right: 0px;
      font-size: $h5-font-size;
    }
    .main_login_text {
      padding-bottom: 4px;
      @include breakpoint(ipad) {
        padding-bottom: 2px;
      }
    }
  }
  .form-inline {
    @include breakpoint(mobile) {
      margin-right: 60px;
    }
  }
}
/* -------- End Nav and Hero Section CSS ---------- */
