// Importing styles
@use './components/typography' as *;

// Colors
$primary: rgba(13, 114, 255, 0.1);
$secondary: #0d72ff;
$font-color: #515151;
$danger: rgb(148, 48, 48);
$error: #d42a2a;
$white: #fff;
$text: #252525;
$text-highlight: #0d72ff;
$light-color: #a9a9aa;
$transparent: rgba(255, 255, 255, 0);
$error-Color: rgb(148, 48, 48);
$error-hover: #a10f0f;
$get-in-touch-error-Color: #ffcb0f;
$success-color: rgb(24, 87, 24);
$formulary-color: #35465b;
$subheading: #adadad;
$orderbg: #f5f5f5;
$buttonbg: #0d72ff;
$secondary-hover: #1d1d1d;
$overlaycolor: #88bafe99;
$bordercolor: #dedede;

$form-field: #212529;
// gradiant colors
$primary-gradient: linear-gradient(143.73deg, #577775, $primary);
// paragraph color
$para-font-size: #7a7771;
// Order Details
$approve-text: #39a956;
$approve-bg: #dcffe5;
$approve-border: rgba(0, 255, 66, 0.07);

$refund-text: #ffb900;
$refund-bg: #fffbf1;
$refund-border: rgba(255, 182, 0, 0.07);

$error-text: #a72121;
$error-bg: #f9dbdbfa;
$error-border: rgba(255, 182, 0, 0.07);

// $cancel-text: ;
// $cancel-bg: ;
// $cancel-border: ;

//card color code in order screen
$box-shadow-card-add: #d3d3d3;
$card-bg-add: #fdfdfd;

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  scroll-behavior: smooth;
}
.delivery_partner {
  display: block;
}
.hideLoader {
  display: none !important;
}
.owl-carousel .owl-nav.disabled {
  display: block !important;
}
.main_common_title {
  font-size: $maintitle;
  color: $text;
  font-weight: $normal-font;
  .common_span {
    color: $buttonbg;
    position: relative;
    margin-right: 16px;
    &::before {
      content: '';
      height: 109px;
      width: 78px;
      border: 2px solid $text;
      border-radius: 5px;
      display: inline-block;
      position: absolute;
      left: -15px;
      top: -20px;
      z-index: -2;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: -1;
      background-color: #fff;
    }
  }
}

// learn button

.learn_button {
  font-size: $h4-font-size;
  color: $white;
  background-color: $buttonbg;
  padding: 15px 30px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: $normal-font;
  transition: all ease-in 0.2s;
  border: 1px solid transparent;
  &:hover {
    background-color: $white !important;
    color: $buttonbg !important;
    border-color: $buttonbg !important;
  }
}

// wave design
.wave_blue {
  position: relative;
  &::before {
    content: '';
    background-image: url('../images/wave.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 83px;
    height: 61px;
    left: 0;
    top: 0px;
  }
}
.Aotmainlogo {
  width: 56px;
  height: 66px;
  object-fit: contain;
}
#react-burger-menu-btn {
  display: none;
}
@media screen and (max-width: 1399px) {
  #pharmacy_software .wave_blue::before {
    display: none;
  }
  .learn_button {
    font-size: 20px;
    padding: 10px 20px;
  }
}
@media screen and (max-width: 1199px) {
  .main_common_title {
    font-size: 38px;
  }
  .main_common_title .common_span::before {
    height: 93px;
    width: 60px;
  }
  .wave_blue::before {
    display: none;
  }
}

@media screen and (max-width: 1120px) {
  #hero_section {
    height: 590px;
  }
}
@media screen and (max-width: 1050px) {
  #hero_section {
    height: 560px;
  }
}
@media screen and (max-width: 1030px) {
  #hero_section {
    height: 540px;
  }
}
@media screen and (max-width: 1010px) {
  #hero_section {
    height: 520px;
  }
}
@media screen and (max-width: 991px) {
  .overlay_btn_slide {
    margin: 0 auto !important;
  }

  .Aotmainlogo {
    width: 50px;
  }
  $background-color_1: #fff;

  #react-burger-menu-btn {
    display: block;
    width: 38px !important;
    height: 29px !important;
    font-size: 0px !important;
    right: 18px !important;
    left: unset !important;
    background-image: url('../images/humburger.svg') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    top: 83px !important;
    background-size: 29px !important;
  }
  .navbar-toggler {
    display: none;
  }
  .bm-menu-wrap {
    width: 100% !important;
    background-color: $background-color_1 !important;
    top: 0px;
    height: 100vh !important;
    .bm-item {
      .common_button {
        max-width: 126px;
        margin: 0 auto;
        display: block;
      }
      padding-top: 25px;
      padding-bottom: 25px;
      .navbar-nav {
        .nav-item {
          padding-left: 0px !important;
          padding-right: 0px !important;
          .nav-link {
            border-bottom: 1px solid #e8e8e8;
          }
        }
      }
    }
    .bm-item-list {
      padding-top: 5px;
      padding-left: 22px;
      padding-right: 22px;
      .close_main_btn {
        padding: 0px;
        width: 25px;
        height: 25px;
        background-image: url('../images/closebtn.svg');
        background-repeat: no-repeat;
        position: absolute;
        right: 20px;
        top: 51px;
        background-size: 24px;
        cursor: pointer;
      }
    }
  }
  #main_header {
    .bm-overlay {
      background-color: $background-color_1 !important;
    }
  }
  .learn_button {
    font-size: 20px;
    padding: 10px 24px;
    border-radius: 10px;
  }
  .main_common_title .common_span {
    margin-right: 8px;
  }
}
@media screen and (max-width: 767px) {
  #react-burger-menu-btn {
    background-size: 26px !important;
    // top: 38px !important;
    top: 84px !important;
  }
}
@media screen and (max-width: 499px) {
  #top_sub_header .sub_header .header_numbers {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  #top_sub_header .sub_header .header_numbers .aot_main_title {
    margin-right: 0px;
  }
  #main_header {
    margin-top: 76.8px;
  }
  #react-burger-menu-btn {
    top: 114px !important;
  }
}
@media screen and (max-width: 575px) {
  .bm-item.form-inline {
    margin-right: 0px !important;
  }
  .learn_button {
    font-size: 16px;
    padding: 8px 20px;
    padding-bottom: 10px;
  }
  .main_common_title {
    font-size: $para-title-font-size;
  }
  .main_common_title .common_span::before {
    height: 52px;
    width: 31px;
    left: -5px;
    top: -10px;
  }
  .bm-menu-wrap .bm-item .common_button {
    max-width: 110px;
  }
  #react-burger-menu-btn {
    width: 30px !important;
    height: 26px !important;
  }
}
//Import Bootstrap 5
// Custom.scss
// Option B: Include parts of Bootstrap

// 6. Add additional custom code here

@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';
